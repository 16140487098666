import type { NextPage, GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { PageProps } from './[slug]'

import WebsiteLayout from '../components/WebsiteLayout'
import PageBlocks from '../components/website/PageBlocks'
import Hero from '../components/website/Hero'

import getHomepage from '../services/website/getHomepage'
import getGlobalFlags from '../services/launch-darkly/getGlobalFlags'

import {
  formatFooterFields,
  formatNavFields,
} from '../utils/contentful/format-global-fields'

import { getHomeContent } from '../services/website/get-home-content'

const WebsiteHome: NextPage<PageProps> = ({
  content,
  nav,
  footer,
  siteBanner,
  marketingBanner,
  customerValueCards,
  pressCards
}) => {
  return (
    <WebsiteLayout
      nav={nav}
      footer={footer}
      siteBanner={siteBanner}
      marketingBanner={marketingBanner}
      seoTitle={content?.pageCollection.items[0]?.seoTitle}
      seoDescription={content?.pageCollection.items[0]?.seoDescription}
      seoImage={content?.pageCollection.items[0]?.seoImage?.url}
    >
      <Hero hero={content?.pageCollection.items[0]?.hero} />
      <div className="mx-auto max-w-1512 space-y-16 py-20 px-16 md:space-y-40 lg:py-40 lg:px-32">
        <PageBlocks
          blocks={content?.pageCollection.items[0]?.blocksCollection?.items}
          pressCards={pressCards}
          customerValueCards={customerValueCards}
        />
      </div>
      <span className="hidden">v2.0.12</span>
    </WebsiteLayout>
  )
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getStaticProps: GetStaticProps = async ({ locale = '' }: any) => {
  const content = await getHomepage()
  const globalFlags = await getGlobalFlags()

  const { customerValueCards, pressCards } = await getHomeContent()

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'website'])),
      content: content,
      siteBanner: content?.globals.siteBanner,
      marketingBanner: content?.globals.marketingBanner,
      nav: formatNavFields(content),
      footer: formatFooterFields(content),
      globalFlags,
      customerValueCards,
      pressCards
    },
    revalidate: 120,
  }
}

export default WebsiteHome
